import React, { useContext, useEffect } from 'react';
import { PUMP_SYSTEMS_SUB_PAGES } from '../../../../constants';
import { PumpSystemsContext } from '../PumpSystems';
import BasicViewApplicationCard from '../../BasicView/BasicViewApplicationCard';
import BasicViewBottomCard from '../../BasicView/BasicViewBottomCard';
import BasicViewCard from '../../BasicView/BasicViewCard';

const PumpSystemBasicView = () => {
    const { onSubPageChange } = useContext(PumpSystemsContext);


    useEffect(() => {
        onSubPageChange && onSubPageChange(PUMP_SYSTEMS_SUB_PAGES.indexOf('basic'));
    }, [onSubPageChange]);

    return (
        <div className='systems-comparison-grid'>
            <div className='default-box'><BasicViewApplicationCard applicationType='pump' /></div>
            <div className='reference-box'><BasicViewCard applicationType='pump' systemType='reference' /></div>
            <div className='alternative-box'><BasicViewCard applicationType='pump' systemType='alternative' /></div>
            <div className='bottom-box'><BasicViewBottomCard applicationType='pump' /></div>
        </div>
    );
};

export default PumpSystemBasicView;
