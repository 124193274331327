import React, { CSSProperties, KeyboardEvent } from 'react';
import Icon, { IconType } from './Icon';

interface LinkProps {
    text: string | number;
    textStyle?: CSSProperties;
    onClick: () => void;
    dark?: boolean;
    icon?: IconType;
    iconPosition?: 'left' | 'right';
    iconRotation45?: boolean;
    style?: CSSProperties;
}

const Link = ({ text, textStyle, dark, icon, onClick, iconPosition, iconRotation45, style }: LinkProps) => {
    const iconPositionClass = iconPosition ? `link-icon-position-${iconPosition}` : '';
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`link ${dark ? 'dark' : ''} ${iconPositionClass} ${iconRotation45 ? 'icon-rotation-45' : ''}`} tabIndex={0} onClick={onClick} onKeyDown={handleKeyDown} style={style}>
            <div style={textStyle}>{text}</div>
            {icon &&
                <div className='link-icon-container'>
                    <Icon type={icon} />
                </div>
            }
        </div>
    );
};

export default Link;
