import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DEFAULT_APPLICATION_POWER_DEMAND,
    DEFAULT_ALTERNATIVE_ENERGY_SAVINGS,
    DEFAULT_ALTERNATIVE_EXPECTED_ENERGY_DEMAND,
    DEFAULT_ALTERNATIVE_CO2_EMISSION_SAVINGS,
    DEFAULT_REFERENCE_ENERGY_DEMAND,
    APPLICATION_INITIAL_MODE
} from '../constants';
import {
    AllocationProfile,
    ApplicationParameters,
    DefaultApplicationInputs,
    Fan,
    FanSystemState,
    OperatingHour,
    OperationProfile,
    SupplyType,
    SystemConfiguration,
    SystemInputParameter
} from '../models';
import { ApplicationMode } from '../types';

const initialState: FanSystemState = {
    alternativeCo2EmissionSavings: DEFAULT_ALTERNATIVE_CO2_EMISSION_SAVINGS,
    alternativeEnergySavings: DEFAULT_ALTERNATIVE_ENERGY_SAVINGS,
    alternativeExpectedEnergyDemand: DEFAULT_ALTERNATIVE_EXPECTED_ENERGY_DEMAND,
    alternativeSystem: null,
    alternativeSystemLoading: false,
    alternativeSystemInvestmentCosts: 0,
    alternativeSystemRequestParameters: null,
    applicationPowerDemand: DEFAULT_APPLICATION_POWER_DEMAND,
    fullScreenChart: false,
    application: null,
    defaultApplicationInputs: null,
    applicationMode: APPLICATION_INITIAL_MODE,
    applicationRequestParameters: null,
    referenceSystem: null,
    referenceSystemLoading: false,
    referenceSystemInvestmentCosts: 0,
    referenceSystemRequestParameters: null,
    supplyType: null,
    supplyTypes: null,
    systemsLoading: false,
    referenceEnergyDemand: DEFAULT_REFERENCE_ENERGY_DEMAND,
    referenceHasMepsWarning: false,
    alternativeHasMepsWarning: false
};

export const fanSystemSlice = createSlice({
    name: 'fanSystem',
    initialState,
    reducers: {
        setFanSystemsLoading: (state, action: PayloadAction<boolean>) => {
            state.systemsLoading = action.payload;
        },
        setFanRequestParameters: (state, action: PayloadAction<ApplicationParameters>) => {
            state.applicationRequestParameters = action.payload;
        },
        setFanAlternativeSystem: (state, action: PayloadAction<SystemConfiguration>) => {
            state.alternativeSystem = action.payload;
        },
        setFanAlternativeSystemLoading: (state, action: PayloadAction<boolean>) => {
            state.alternativeSystemLoading = action.payload;
        },
        setFanAlternativeSystemInvestmentCosts: (state, action: PayloadAction<number>) => {
            state.alternativeSystemInvestmentCosts = action.payload;
        },
        setFanAlternativeSystemRequestParameters: (state, action: PayloadAction<SystemInputParameter[]>) => {
            state.alternativeSystemRequestParameters = action.payload;
        },
        setFanReferenceSystem: (state, action: PayloadAction<SystemConfiguration>) => {
            state.referenceSystem = action.payload;
        },
        setFanReferenceSystemLoading: (state, action: PayloadAction<boolean>) => {
            state.referenceSystemLoading = action.payload;
        },
        setFanReferenceSystemInvestmentCosts: (state, action: PayloadAction<number>) => {
            state.referenceSystemInvestmentCosts = action.payload;
        },
        setFanReferenceSystemRequestParameters: (state, action: PayloadAction<SystemInputParameter[]>) => {
            state.referenceSystemRequestParameters = action.payload;
        },
        setFanFullScreenChart: (state, action: PayloadAction<boolean>) => {
            state.fullScreenChart = action.payload;
        },
        setFanSupplyType: (state, action: PayloadAction<SupplyType>) => {
            state.supplyType = action.payload;
        },
        setFanSupplyTypes: (state, action: PayloadAction<SupplyType[]>) => {
            state.supplyTypes = action.payload;
        },
        setFan: (state, action: PayloadAction<Fan>) => {
            state.application = action.payload;
        },
        setDefaultFanInputs: (state, action: PayloadAction<DefaultApplicationInputs>) => {
            state.defaultApplicationInputs = action.payload;
        },
        setFanApplicationMode: (state, action: PayloadAction<ApplicationMode>) => {
            state.applicationMode = action.payload;
        },
        setFanAllocationProfile: (state, action: PayloadAction<AllocationProfile>) => {
            if (state.application) {
                state.application.operationProfile.allocationProfile = action.payload;
            }
        },
        setFanOperatingHour: (state, action: PayloadAction<OperatingHour>) => {
            if (state.application) {
                state.application.operationProfile.operatingHour = action.payload;
            }
        },
        setFanOperationProfile: (state, action: PayloadAction<OperationProfile>) => {
            if (state.application) {
                state.application.operationProfile = action.payload;
            }
        },
        setFanShaftPower: (state, action: PayloadAction<number>) => {
            if (state.application) {
                state.application.shaftPower = action.payload;
            }
        },
        setFanSpeed: (state, action: PayloadAction<number>) => {
            if (state.application) {
                state.application.speed = action.payload;
            }
        },
        setFanApplicationPowerDemand: (state, action: PayloadAction<number[]>) => {
            state.applicationPowerDemand = action.payload;
        },
        setFanHasMepsWarning: (state, action: PayloadAction<{ hasMepsWarning: boolean, systemType: string }>) => {
            const hasMepsWarning = action.payload.hasMepsWarning;
            const systemType = action.payload.systemType;

            if (systemType == 'reference') {
                state.referenceHasMepsWarning = hasMepsWarning;
            } else if (systemType == 'alternative') {
                state.alternativeHasMepsWarning = hasMepsWarning;
            }
        }
    }
});

export const {
    setFanAlternativeSystem,
    setFanAlternativeSystemInvestmentCosts,
    setFanAlternativeSystemLoading,
    setFanAlternativeSystemRequestParameters,
    setFanApplicationPowerDemand,
    setFanFullScreenChart,
    setFan,
    setDefaultFanInputs,
    setFanAllocationProfile,
    setFanOperatingHour,
    setFanOperationProfile,
    setFanRequestParameters,
    setFanShaftPower,
    setFanSpeed,
    setFanReferenceSystem,
    setFanReferenceSystemInvestmentCosts,
    setFanReferenceSystemLoading,
    setFanReferenceSystemRequestParameters,
    setFanSupplyType,
    setFanSupplyTypes,
    setFanSystemsLoading,
    setFanApplicationMode,
    setFanHasMepsWarning
} = fanSystemSlice.actions;
export const fanSystemReducer = fanSystemSlice.reducer;

