import React, { useEffect, useMemo } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { TRANSLATIONS } from '../../constants';
import { useAppDispatch, useAppSelector, useTranslate } from '../../hooks/common';
import { Currency } from '../../models';
import { useCurrency, useHook } from '../../hooks';
import { setActiveTab } from '../../store';
import ContentContainer from '../common/ContentContainer';
import Box from '../common/Box';
import Flex from '../common/Flex';
import Select from '../common/Select';
import Text from '../common/Text';
import TextInput from '../common/TextInput';
import IconButton from '../common/IconButton';
import { IconType } from '../common/Icon';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const dispatch = useAppDispatch();
    const dark = useAppSelector(x => x.layout.darkMode);
    const navigate = useNavigate();
    const { regionSettings } = useAppSelector(x => x.regionSettings);
    const { language } = useAppSelector(x => x.translation);
    const { currencySymbol } = useCurrency();
    const { dynamicTranslate, translate } = useTranslate();
    const {
        getLanguageFromCode,
        handleCo2FactorChange,
        handleCountrySelect,
        handleCurrencySelect,
        handleEnergyPriceChange,
        handleLanguageSelect,
        languages,
        exchangedEnergyPrice
    } = useHook(x => x.regionSettings);

    const numberFormatOptions: NumericFormatProps<InputAttributes> = useMemo(() => ({
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 2,
        allowLeadingZeros: false
    }), []);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTab(undefined));
    }, [dispatch]);

    const selectedCountry = regionSettings?.countries?.find(x => x.code === regionSettings.countryCode);
    
    return (
        <ContentContainer>
            <div className='settings' style={{ paddingTop: 28 }}>
                <Flex justification='flex-start' alignment='center'>
                    <IconButton dark={dark} iconType={IconType.ArrowLeft} size={40} onClick={() => navigate(-1)} style={{ marginBottom: 24 }} />
                    <Text type='title' dark={dark} style={{ marginBottom: 24, marginLeft: 12 }}>{translate(TRANSLATIONS.generated['Settings.Settings'])}</Text>
                </Flex>
                <Box dark={dark} layer={1} style={{ marginBottom: 24 }}>
                    <Text type='box-title' dark={dark}>{translate(TRANSLATIONS.generated['Main.LanguageText'])}</Text>
                    <Flex direction='column' alignment='start' rowGap={24} style={{ paddingTop: 24 }}>
                        <Select
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Main.LanguageText'])}
                            value={language}
                            values={languages}
                            mapToString={getLanguageFromCode}
                            onSelect={handleLanguageSelect}
                            filled={x => x !== undefined}
                        />
                    </Flex>
                </Box>
                <Box dark={dark} layer={1}>
                    <Text type='box-title' dark={dark}>{translate(TRANSLATIONS.main.region)}</Text>
                    <Flex direction='column' alignment='start' rowGap={24} style={{ paddingTop: 24 }}>
                        <Select
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Settings.Country'])}
                            value={selectedCountry ?? { code: '--', name: 'Default' }}
                            values={regionSettings?.countries ?? []}
                            mapToString={x => x.name}
                            onSelect={handleCountrySelect} />
                        <Select
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['Common.Currency'])}
                            value={regionSettings?.currency ?? {} as Currency}
                            values={regionSettings?.currencies ?? []}
                            mapToString={x => x ? dynamicTranslate(TRANSLATIONS.generated, `System.Currencies.${x.code}.Name`) : ''}
                            onSelect={handleCurrencySelect} />
                        <TextInput
                            label={translate(TRANSLATIONS.generated['System.EnergyPrice'])}
                            name='energyPrice'
                            dark={dark}
                            value={exchangedEnergyPrice?.toString() ?? '0'}
                            numberFormatOptions={{ ...numberFormatOptions, suffix: ` ${currencySymbol}/${translate(TRANSLATIONS.generated['System.Units.Kilowatthour'])}` }}
                            onChange={handleEnergyPriceChange} />
                        <TextInput
                            label={translate(TRANSLATIONS.generated['Common.Co2FactorBasic'])}
                            name='co2Factor'
                            dark={dark}
                            value={regionSettings?.co2Factor?.toString() ?? '0'}
                            numberFormatOptions={{ ...numberFormatOptions, suffix: ` ${translate(TRANSLATIONS.generated['System.Units.Gramsperkilowatthour'])}` }}
                            onChange={handleCo2FactorChange} />
                    </Flex>
                </Box>
            </div>
        </ContentContainer>);
};

export default Settings;
