import React, { useCallback, useEffect } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { GovernmentFundingData } from '../../../models';
import { TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../../constants';
import { useCurrency } from '../../../hooks';
import { useTranslate, useValidate } from '../../../hooks/common';
import { useGovernmentFunding } from '../../../hooks/application/GovernmentFunding';
import { getPercentageValidationMessage, getRangeValidationMessage, isInRange, isPercentage } from '../../../services';
import Box from '../../common/Box';
import Button, { ButtonType } from '../../common/Button';
import Flex from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import IconButton from '../../common/IconButton';
import InputValidation from '../../common/InputValidation';
import Modal from '../../common/Modal';
import Text from '../../common/Text';
import TextInput from '../../common/TextInput';
import CommercialViewGovernmentFundingInfo from './ModalInfo/CommercialViewGovernmentFundingInfo';

export interface GovernmentFundingProps {
    color: 'reference' | 'alternative';
    governmentFundingData: GovernmentFundingData;
    dark?: boolean;
    totalInvestment: number;
    exchangedTotalInvestment: number;
    onGovernmentFundingDataChange: (value: Partial<GovernmentFundingData>) => void;
    onNettoInvestmentChange: (value: number) => void;
}

const numberFormatOptions: NumericFormatProps<InputAttributes> = {
    thousandSeparator: ',',
    allowNegative: false,
    decimalSeparator: '.',
    decimalScale: 2,
    allowLeadingZeros: false
};

const modalContent = (
    <>
        <div>Additional investment</div>
        <div>Modal description</div>
    </>
);

const GovernmentFunding = ({
    color,
    dark,
    governmentFundingData,
    onGovernmentFundingDataChange,
    onNettoInvestmentChange,
    totalInvestment,
    exchangedTotalInvestment
}: GovernmentFundingProps) => {
    const {
        grantRate,
        grantSum,
        closeGovernmentFunding,
        handleEnableEditChange,
        handleGrantRateChange,
        handleGrantSumChange,
        handleModalOpenChange,
        exchangedNettoInvestment,
        nettoInvestment
    } = useGovernmentFunding({ governmentFundingData, onGovernmentFundingDataChange, totalInvestment, exchangedTotalInvestment });

    useEffect(() => {
        onNettoInvestmentChange(nettoInvestment);
    }, [nettoInvestment, onNettoInvestmentChange]);
    const { translate } = useTranslate();
    const handleOpenModalClick = useCallback(() => handleModalOpenChange(true), [handleModalOpenChange]);
    const handleCloseModalClick = useCallback(() => handleModalOpenChange(false), [handleModalOpenChange]);
    const handleEnableEditClick = useCallback(() => handleEnableEditChange(true), [handleEnableEditChange]);
    const { currencyFormat } = useCurrency();

    const isGrantSumValid = (value: string): boolean => isInRange(value, 0, totalInvestment);

    const { value: grantRateInputValue, setValue: setGrantRateInputValue } = useValidate(grantRate, isPercentage, handleGrantRateChange, TEXT_INPUT_DEBOUNCE_TIME);
    const { value: grantSumInputValue, setValue: setGrantSumInputValue } = useValidate(grantSum, isGrantSumValid, handleGrantSumChange, TEXT_INPUT_DEBOUNCE_TIME);

    const getGrantSumValidationMessage = (): JSX.Element => (
        <>
            {getRangeValidationMessage(translate, '0', totalInvestment.toString())}
            <br />
            {translate(TRANSLATIONS.generated['Errors.GovernmentSum'])}
        </>
    );

    return (
        <>
            {!governmentFundingData.enableEdit && (
                <Flex direction='column'>
                    <Button
                        dark={dark}
                        roundedBorder
                        style={{ padding: '10px 16px' }}
                        type={ButtonType.Secondary}
                        onClick={handleEnableEditClick}
                    >
                        <Icon type={IconType.AddWorkorder} />
                        {translate(TRANSLATIONS.generated['System.GovernmentFunding'])}
                    </Button>
                </Flex>
            )}
            {governmentFundingData.enableEdit && (
                <Box edgeColor={color} dark={dark} style={{ padding: 16 }}>
                    <Flex justification='space-between' gap={16} style={{ paddingBottom: 8 }}>
                        <Text color={color} dark={dark} type='subtitle-small'>
                            {translate(TRANSLATIONS.generated['System.GovernmentFunding'])}
                        </Text>
                        <Flex gap={8}>
                            <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />
                            <IconButton dark={dark} iconType={IconType.Close} size={24} layer={2} onClick={closeGovernmentFunding} />
                        </Flex>
                    </Flex>
                    <div style={{ paddingTop: 16 }}>
                        <InputValidation
                            valid={isPercentage(grantRateInputValue)}
                            message={getPercentageValidationMessage(translate)}
                            dark={dark}
                        >
                            <TextInput
                                onChange={setGrantRateInputValue}
                                numberFormatOptions={{ ...numberFormatOptions, suffix: '%' }}
                                dark={dark}
                                label={translate(TRANSLATIONS.generated['System.GrantRate'])}
                                name='grantRate'
                                value={grantRateInputValue}
                                error={!isPercentage(grantRateInputValue)}
                            />
                        </InputValidation>
                        <InputValidation
                            valid={isGrantSumValid(grantSumInputValue)}
                            message={getGrantSumValidationMessage()}
                            dark={dark}
                        >
                            <TextInput
                                onChange={setGrantSumInputValue}
                                numberFormatOptions={currencyFormat}
                                style={{ paddingTop: 16 }}
                                dark={dark}
                                label={translate(TRANSLATIONS.generated['System.GrantSum'])}
                                name='grantSum'
                                value={grantSumInputValue}
                                error={!isGrantSumValid(grantSumInputValue)}
                            />
                        </InputValidation>
                    </div>

                    <div>
                        <TextInput
                            style={{ paddingTop: 24 }}
                            label={translate(TRANSLATIONS.generated['System.NettoInvestment'])}
                            readOnly
                            numberFormatOptions={currencyFormat}
                            withFullBorder
                            dark={dark}
                            value={exchangedNettoInvestment?.toString() ?? '0'}
                            name='nettoInvestment'
                        />
                    </div>
                    {modalContent && (
                        <Modal
                            open={governmentFundingData.isModalOpen}
                            allowClickAway
                            movable
                            onClose={handleCloseModalClick}
                            dark={dark}
                            key={'info-modal'}
                        >
                            <CommercialViewGovernmentFundingInfo />
                        </Modal>
                    )}
                </Box>
            )}
        </>
    );
};

export default GovernmentFunding;
