import React, { CSSProperties, ReactNode } from 'react';

interface TextProps {
    children: ReactNode;
    color?: 'reference' | 'alternative'; 
    type: 'box-title' | 'label' | 'normal' | 'small' | 'subordinate' | 'subtitle' | 'subtitle-small' | 'subtitle-very-small' | 'subtitle-very-small-light' | 'title' | 'title-label';
    dark?: boolean;
    style?: CSSProperties;
}

const Text = ({ children, color, dark, style, type }: TextProps) => {
    const textColor = `text-color-${color}`;

    return (
        <div className={`text text-${type} ${dark ? 'dark' : ''} ${color ? textColor : ''}`} style={style}>
            {children}
        </div>
    );
};

export default Text;
