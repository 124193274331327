import { CommercialViewState } from '../models/CommercialViewState';
import { DEFAULT_ADDITIONAL_INVESTMENT } from './AdditionalInvestment';

export const COMMERCIAL_VIEW_INITIAL_STATE: CommercialViewState = {
    alternativeSystemCommercialData: {
        additionalInvestments: {
            additionalInvestmentItems: [DEFAULT_ADDITIONAL_INVESTMENT],
            enableEdit: false,
            isModalOpen: false
        },
        controlUnitPrices: {
            customerPrice: '0',
            discount: '0',
            expenditureForRepairs: '0',
            listPrice: 0
        },
        governmentFunding: {
            enableEdit: false,
            grantRate: '',
            grantSum: '',
            isModalOpen: false
        },
        motorPrices: {
            customerPrice: '0',
            discount: '0',
            expenditureForRepairs: '0',
            listPrice: 0
        },
        totalInvestment: 0
    },
    referenceSystemCommercialData: {
        additionalInvestments: {
            additionalInvestmentItems: [DEFAULT_ADDITIONAL_INVESTMENT],
            enableEdit: false,
            isModalOpen: false
        },
        controlUnitPrices: {
            customerPrice: '0',
            discount: '0',
            expenditureForRepairs: '0',
            listPrice: 0
        },
        governmentFunding: {
            enableEdit: false,
            grantRate: '',
            grantSum: '',
            isModalOpen: false
        },
        motorPrices: {
            customerPrice: '0',
            discount: '0',
            expenditureForRepairs: '0',
            listPrice: 0
        },
        totalInvestment: 0
    }
};
