import { ComponentType, InputType } from '../enums';
import { AllocationProfile, EfficiencyProfile, OperatingHour, OperationProfile, RegionSettings, SupplyType } from '../models';
import { ImageDropdownItem } from '../models/system/inputs/ImageDropdownItem';
import { KeyValuePair } from '../models/system/inputs/KeyValuePair';

export const DEFAULT_ALLOCATIONS: AllocationProfile[] = [
    {
        id: 1,
        name: 'System.AllocationProfile.Default',
        p10: 0.00,
        p20: 0.00,
        p30: 1.00,
        p40: 2.00,
        p50: 3.00,
        p60: 4.00,
        p70: 5.00,
        p80: 4.00,
        p90: 2.00,
        p100: 3.00
    },
    {
        id: 2,
        name: 'System.AllocationProfile.BellShaped',
        p10: 0.00,
        p20: 0.00,
        p30: 1.60,
        p40: 2.40,
        p50: 3.60,
        p60: 4.40,
        p70: 4.40,
        p80: 3.60,
        p90: 2.40,
        p100: 1.60
    },
    {
        id: 3,
        name: 'System.AllocationProfile.EqualSpread',
        p10: 0.00,
        p20: 0.00,
        p30: 3.00,
        p40: 3.00,
        p50: 3.00,
        p60: 3.00,
        p70: 3.00,
        p80: 3.00,
        p90: 3.00,
        p100: 3.00
    },
    {
        id: 4,
        name: 'System.AllocationProfile.Increasing',
        p10: 0.00,
        p20: 0.00,
        p30: 0.00,
        p40: 0.00,
        p50: 1.00,
        p60: 2.00,
        p70: 3.00,
        p80: 4.00,
        p90: 6.00,
        p100: 8.00
    },
    {
        id: 5,
        name: 'System.AllocationProfile.Decreasing',
        p10: 0.00,
        p20: 0.00,
        p30: 0.00,
        p40: 0.00,
        p50: 8.00,
        p60: 6.00,
        p70: 4.00,
        p80: 3.00,
        p90: 2.00,
        p100: 1.00
    },
    {
        id: 6,
        name: 'System.AllocationProfile.Constant100',
        p10: 0.00,
        p20: 0.00,
        p30: 0.00,
        p40: 0.00,
        p50: 0.00,
        p60: 0.00,
        p70: 0.00,
        p80: 0.00,
        p90: 0.00,
        p100: 24.00
    },
    {
        id: 7,
        name: 'System.AllocationProfile.Constant90_100',
        p10: 0.00,
        p20: 0.00,
        p30: 0.00,
        p40: 0.00,
        p50: 0.00,
        p60: 0.00,
        p70: 0.00,
        p80: 0.00,
        p90: 12.00,
        p100: 12.00
    }
];

export const DEFAULT_ALTERNATIVE_EFFICIENCY_PROFILE: EfficiencyProfile = {
    applicationKind: 1,
    id: 2,
    isDeleted: false,
    isPredefined: false,
    name: 'alternativeEfficiencyProfile',
    p10: 0.18,
    p20: 0.24,
    p30: 0.38,
    p40: 0.65,
    p50: 1.09,
    p60: 1.80,
    p70: 2.77,
    p80: 4.12,
    p90: 7.49,
    p100: 7.97
};

export const DEFAULT_ALTERNATIVE_EXPECTED_ENERGY_DEMAND = '28,38 MWh/a';
export const DEFAULT_ALTERNATIVE_ENERGY_SAVINGS = '26,40 MWh/a';
export const DEFAULT_ALTERNATIVE_CO2_EMISSION_SAVINGS = '16,7 t/a';
export const DEFAULT_REFERENCE_ENERGY_DEMAND = '54,77 MWh/a';

export const DEFAULT_APPLICATION_POWER_DEMAND: number[] = [
    0.0072240689655172444,
    0.057792551724137955,
    0.19504986206896566,
    0.46234041379310364,
    0.9030086206896554,
    1.5603988965517244,
    2.4778556551724136,
    3.698723310344828,
    5.266346275862069,
    7.22406896551724
];

export const DEFAULT_REFERENCE_POWER_DEMAND: number[] = [
    4.02616389380531,
    4.265341946902655,
    4.624109026548672,
    4.9430130973451325,
    5.2619171681415935,
    5.740273274336283,
    6.218629380530974,
    6.696985486725663,
    7.494245663716813,
    7.972601769911504
];

export const DEFAULT_ALTERNATIVE_POWER_DEMAND: number[] = [
    0.18460799999999997,
    0.23769599999999994,
    0.3771840000000001,
    0.646272,
    1.08816,
    1.80062208,
    2.767199999999999,
    4.121425919999999,
    5.807293439999999,
    7.896959999999997
];

export const DEFAULT_OPENING_HOURS: OperatingHour[] = [
    {
        id: 1,
        factor: 0.25,
        workingDaysPerYear: 250,
        workingHoursPerDay: 6,
        workingHoursPerYear: 1500
    },
    {
        id: 2,
        factor: 0.5,
        workingDaysPerYear: 250,
        workingHoursPerDay: 12,
        workingHoursPerYear: 3000
    },
    {
        id: 3,
        factor: 0.75,
        workingDaysPerYear: 250,
        workingHoursPerDay: 18,
        workingHoursPerYear: 4500
    },
    {
        id: 4,
        factor: 1,
        workingDaysPerYear: 250,
        workingHoursPerDay: 24,
        workingHoursPerYear: 6000
    },
    {
        id: 5,
        factor: 1,
        workingDaysPerYear: 365,
        workingHoursPerDay: 24,
        workingHoursPerYear: 8750
    }
];

export const DEFAULT_OPERATION_PROFILE: OperationProfile = {
    applicationKind: 1,
    id: 1,
    allocationProfile: DEFAULT_ALLOCATIONS[0],
    operatingHour: DEFAULT_OPENING_HOURS[0],
    isDeleted: false,
    isPredefined: false,
    name: 'operationProfile',
    p10: 0,
    p20: 0,
    p30: 1,
    p40: 2,
    p50: 3,
    p60: 4,
    p70: 5,
    p80: 4,
    p90: 2,
    p100: 3,
    workingDaysPerYear: 365,
    translatedName: 'Default'
};

export const DEFAULT_PUMP_SPEED = 1450;

export const DEFAULT_REGION_SETTINGS_DE: RegionSettings = {
    co2Factor: 632,
    countryCode: 'de',
    currency: {
        id: 1,
        code: 'EUR',
        country: 'Europe',
        isAvailable: true,
        label: 'System.Currencies.EUR',
        mapping: 'Europe',
        rate: 0,
        rateToEuro: 1,
        rateToUsd: 0.9136
    },
    currencies: [{
        id: 1,
        code: 'EUR',
        country: 'Europe',
        isAvailable: true,
        label: 'System.Currencies.EUR',
        mapping: 'Europe',
        rate: 0,
        rateToEuro: 1,
        rateToUsd: 0.9136
    },
        {
            id: 79,
            code: 'USD',
            country: 'United States',
            rate: 0,
            rateToEuro: 1.0886,
            rateToUsd: 1,
            label: 'System.Currencies.USD',
            isAvailable: true,
            mapping: 'United States'
        }
    ],
    countries: [{ code: 'de', name: 'Germany' }, { code: 'us', name: 'United States' }, { code: 'at', name: 'Austria' }],
    energyPrice: 0.12,
    powerUnit: 'kW'
};

export const DEFAULT_REGION_SETTINGS_US: RegionSettings = {
    co2Factor: 632,
    countryCode: 'us',
    currency: {
        id: 79,
        code: 'USD',
        country: 'United States',
        rate: 0,
        rateToEuro: 1.0886,
        rateToUsd: 1,
        label: 'System.Currencies.USD',
        isAvailable: true,
        mapping: 'United States'
    },
    currencies: [{
        id: 1,
        code: 'EUR',
        country: 'Europe',
        isAvailable: true,
        label: 'System.Currencies.EUR',
        mapping: 'Europe',
        rate: 0,
        rateToEuro: 1,
        rateToUsd: 0.9136
    },
    {
        id: 79,
        code: 'USD',
        country: 'United States',
        rate: 0,
        rateToEuro: 1.0886,
        rateToUsd: 1,
        label: 'System.Currencies.USD',
        isAvailable: true,
        mapping: 'United States'
    }
    ],
    countries: [{ code: 'de', name: 'Germany' }, { code: 'us', name: 'United States' }, { code: 'at', name: 'Austria' }],
    energyPrice: 0.1,
    powerUnit: 'hp'
};

export const DEFAULT_REFERENCE_EFFICIENCY_PROFILE: EfficiencyProfile = {
    applicationKind: 1,
    id: 1,
    isDeleted: false,
    isPredefined: false,
    name: 'referenceEfficiencyProfile',
    p10: 4.03,
    p20: 4.27,
    p30: 4.62,
    p40: 4.94,
    p50: 5.26,
    p60: 5.74,
    p70: 6.22,
    p80: 6.70,
    p90: 5.81,
    p100: 7.90
};

export const DEFAULT_SHAFT_POWER = 7.22;

export const DEFAULT_MOTOR_POWERS: KeyValuePair<string, string>[] = [
    {
        value: '7.5',
        key: '1'
    },
    {
        value: '11',
        key: '2'
    },
    {
        value: '15',
        key: '3'
    },
    {
        value: '18.5',
        key: '4'
    }
];

export const DEFAULT_MOTOR_EFFICIENCY_CLASSES: KeyValuePair<string, string>[] = [
    {
        value: 'IE1',
        key: '1'
    },
    {
        value: 'IE2',
        key: '2'
    },
    {
        value: 'IE3',
        key: '3'
    },
    {
        value: 'IE4',
        key: '4'
    }
];

export const DEFAULT_RATED_CURRENTS: KeyValuePair<string, string>[] = [
    {
        value: '26',
        key: '1'
    },
    {
        value: '32',
        key: '2'
    },
    {
        value: '38',
        key: '3'
    },
    {
        value: '45',
        key: '4'
    }
];

export const DEFAULT_DESIGN_TYPES: KeyValuePair<string, string>[] = [
    {
        value: 'Motor starter',
        key: '1'
    },
    {
        value: 'Soft Starter',
        key: '2'
    },
    {
        value: 'Star-Delta Combination',
        key: '3'
    }
];

export const DEFAULT_MOTOR_TECHNOLOGIES: KeyValuePair<string, string>[] = [
    {
        value: 'Asynchronous',
        key: '1'
    },
    {
        key: '2',
        value: 'Synchronous-reluctant'
    }
];

export const DEFAULT_SUPPLY_TYPES: SupplyType[] = [
    {
        id: 1,
        order: 0,
        name: '3AC / 400 V / 50 Hz',
        currentType: '3AC',
        frequency: 50,
        voltage: 400
    },
    {
        id: 2,
        order: 1,
        name: '3AC / 500 V / 50 Hz',
        currentType: '3AC',
        frequency: 50,
        voltage: 500
    },
    {
        id: 3,
        order: 2,
        name: '3AC / 690 V / 50 Hz',
        currentType: '3AC',
        frequency: 50,
        voltage: 690
    }
];

export const DEFAULT_MOTOR_IMAGE_DROPDOWN: ImageDropdownItem = {
    name: 'Motor',
    order: 1,
    id: 'motorType',
    inputType: InputType.ImageDropdown,
    selectedValue: {
        componentType: ComponentType.MotorTypeVsdConverterDuty,
        description: '',
        imageId: 'Simotics',
        title: 'SINAMICS G120X',
        id: 1
    },
    values: [
        {
            description: 'Optimized for converter operation. Synchronous-reluctance and asynchronous technology',
            componentType: ComponentType.MotorTypeVsdStandard,
            id: 1,
            imageId: 'Simotics',
            title: 'SIMOTICS - IEC Inverter Duty Motor'
        },
        {
            description: 'Direct online motor - converter capable. Asynchronous technology',
            componentType: ComponentType.MotorTypeVsdConverterDuty,
            id: 2,
            imageId: 'Simotics',
            title: 'SIMOTICS - IEC Standard Motor'
        },
        {
            description: 'Existing motor - converter capable. Asynchronous technology',
            componentType: ComponentType.MotorTypeThirdParty,
            imageId: 'ExistingMotor',
            id: 3,
            title: 'Existing Motor'
        }
    ]
};

export const DEFAULT_CONVERTER_IMAGE_DROPDOWN: ImageDropdownItem = {
    name: 'Converter',
    order: 1,
    id: 'converterType',
    inputType: InputType.ImageDropdown,
    selectedValue: {
        componentType: ComponentType.ConverterTypeSinamics,
        description: '',
        imageId: 'Sinamics',
        title: 'SINAMICS G120X',
        id: 1
    },
    values: [
        {
            description: '',
            componentType: ComponentType.ConverterTypeSinamics,
            id: 1,
            imageId: 'Sinamics',
            title: 'SINAMICS'
        },
        {
            description: '',
            componentType: ComponentType.ConverterTypeThirdParty,
            id: 2,
            imageId: 'ThirdPartyConverter',
            title: 'Third party converter'
        }
    ]
};

export const DEFAULT_SWITCHGEAR_IMAGE_DROPDOWN: ImageDropdownItem = {
    name: 'Switchgear',
    order: 1,
    id: 'switchgearType',
    inputType: InputType.ImageDropdown,
    selectedValue: {
        componentType: ComponentType.SwitchgearTypeSirius,
        description: '',
        imageId: 'Sirius',
        title: 'SIRIUS',
        id: 1
    },
    values: [
        {
            description: '',
            componentType: ComponentType.SwitchgearTypeSirius,
            id: 1,
            imageId: 'Sirius',
            title: 'SIRIUS'
        },
        {
            description: '',
            componentType: ComponentType.SwitchgearTypeThirdParty,
            id: 2,
            imageId: 'ThirdPartySwitchgear',
            title: 'Third party switchgear'
        }
    ]
};

export const DEFAULT_CONTROL_MODES_ALTERNATIVE: ImageDropdownItem = {
    name: 'Control mode',
    order: 1,
    id: 'controlMode',
    inputType: InputType.ImageDropdown,
    selectedValue: {
        componentType: ComponentType.ConverterTypeSinamics,
        description: 'Controlling by increasing the flow resistance. The pump is always operating with rated speed.',
        imageId: 'Converter',
        descriptionImageId: 'ConverterDescription',
        title: 'Converter',
        id: 1
    },
    values: [
        {
            description: 'Controlling by increasing the flow resistance. The pump is always operating with rated speed.',
            componentType: ComponentType.ControlModeThrottle,
            id: 1,
            imageId: 'Throttle',
            descriptionImageId: 'ThrottleDescription',
            title: 'Throttle'
        },
        {
            description: 'Controlling by increasing the bypass flow. The pump is always operating with rated power.',
            componentType: ComponentType.ControlModeBypass,
            imageId: 'Bypass',
            descriptionImageId: 'BypassDescription',
            id: 2,
            title: 'Bypass'
        },
        {
            componentType: ComponentType.ControlModeConverter,
            description: 'Controlling by reducing the speed of the pump. The pump is always operating on demand.',
            imageId: 'Converter',
            descriptionImageId: 'ConverterDescription',
            title: 'Converter',
            id: 3
        }
    ]
};

export const DEFAULT_CONTROL_MODES_REFERENCE: ImageDropdownItem = {
    name: 'Control mode',
    order: 1,
    id: 'controlMode',
    inputType: InputType.ImageDropdown,
    selectedValue: {
        description: 'Controlling by increasing the flow resistance. The pump is always operating with rated speed.',
        componentType: ComponentType.ControlModeThrottle,
        id: 1,
        imageId: 'Throttle',
        descriptionImageId: 'ThrottleDescription',
        title: 'Throttle'
    },
    values: [
        {
            description: 'Controlling by increasing the flow resistance. The pump is always operating with rated speed.',
            componentType: ComponentType.ControlModeThrottle,
            id: 1,
            imageId: 'Throttle',
            descriptionImageId: 'ThrottleDescription',
            title: 'Throttle'
        },
        {
            description: 'Controlling by increasing the bypass flow. The pump is always operating with rated power.',
            componentType: ComponentType.ControlModeBypass,
            imageId: 'Bypass',
            descriptionImageId: 'BypassDescription',
            id: 2,
            title: 'Bypass'
        },
        {
            componentType: ComponentType.ControlModeConverter,
            description: 'Controlling by reducing the speed of the pump. The pump is always operating on demand.',
            imageId: 'Converter',
            descriptionImageId: 'ConverterDescription',
            title: 'Converter',
            id: 3
        }
    ]
};
