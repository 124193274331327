import { useCallback, useMemo, useRef } from 'react';
import { DataType, InputType } from '../../enums';
import { useSystemsLoad } from './SystemsLoad';
import { ImageDropdownItem } from '../../models';
import { ApplicationType } from '../../types';
import { useAppDispatch, useAppSelector } from '../common';
import { getSystemActions } from '../../services';

export interface PumpSystemsBasicViewParameters {
    applicationType: ApplicationType,
    systemType: 'reference' | 'alternative'
}

export const useBasicView = ({ applicationType, systemType }: PumpSystemsBasicViewParameters) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const isPumpSystem = applicationType === 'pump';
    const dispatch = useAppDispatch();
    const isReferenceSystem = systemType == 'reference';
    const {
        referenceSystem,
        alternativeSystem,
        referenceSystemLoading,
        alternativeSystemLoading,
        referenceSystemRequestParameters,
        alternativeSystemRequestParameters,
        applicationRequestParameters
    } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const { systemLoadingHandler, governmentFundingDataHandler } = getSystemActions(applicationType, systemType);
    const { handleGetSystem } = useSystemsLoad({ applicationType });

    const cardRef = useRef<HTMLDivElement>(null);

    const getWidth = useCallback(() => cardRef?.current?.getBoundingClientRect()?.width ?? 400, []);

    const systemState = useMemo(() => isReferenceSystem
        ? referenceSystem
        : alternativeSystem,
        [alternativeSystem, isReferenceSystem, referenceSystem]);

    const systemLoading = useMemo(() => isReferenceSystem
        ? referenceSystemLoading
        : alternativeSystemLoading, [alternativeSystemLoading, isReferenceSystem, referenceSystemLoading]);

    const systemRequestParameters = useMemo(() => isReferenceSystem
        ? referenceSystemRequestParameters
        : alternativeSystemRequestParameters, [alternativeSystemRequestParameters, isReferenceSystem, referenceSystemRequestParameters]);

    const controlComponent = useMemo(() =>
        systemState?.controlUnitComponent.inputs.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlUnitComponent.inputs]);

    const controlMode = useMemo(() =>
        systemState?.controlModeComponent.inputs?.find(x => x.inputType == InputType.ImageDropdown) as ImageDropdownItem,
        [systemState?.controlModeComponent]);

    const controlModeDescription = controlMode.selectedValue.title;

    const motorComponentDescription = `${systemState?.motorComponent.selectedMotor.marketingName?.marketingName ?? ''}`;

    const controlComponentDescription = useMemo(() =>
        systemState?.controlUnitComponent.selectedControlUnit.marketingName?.marketingName,
        [systemState?.controlUnitComponent.selectedControlUnit.marketingName?.marketingName]);

    const controlModeInput = useMemo(() => systemState?.controlModeComponent?.inputs ?? [], [systemState?.controlModeComponent]);

    const motorInputs = useMemo(() => systemState?.motorComponent.inputs ?? [], [systemState?.motorComponent.inputs]);

    const controlInputs = useMemo(() => systemState?.controlUnitComponent.inputs ?? [], [systemState?.controlUnitComponent.inputs]);

    const handleSystemInputChange = useCallback(async (inputId: string, value: string, dataType?: DataType) => {
        if (value[value.length - 1] === '.') {
            return;
        }
        dispatch(systemLoadingHandler(true));

        const newSystemParameters = systemRequestParameters?.map(x => x.inputId === inputId ? { inputId, value, dataType } : x);

        await (applicationRequestParameters && handleGetSystem({
            countryCode: regionSettings?.countryCode ?? '--',
            applicationParameters: applicationRequestParameters,
            systemInputParameters: newSystemParameters ?? [],
            changedInputId: inputId
        }, systemType));

        dispatch(systemLoadingHandler(false));
        dispatch(governmentFundingDataHandler({
            enableEdit: false,
            grantRate: '',
            grantSum: ''
        }));
    }, [dispatch, systemLoadingHandler, systemRequestParameters, applicationRequestParameters, handleGetSystem, regionSettings?.countryCode, systemType, governmentFundingDataHandler]);

    return {
        cardRef,
        controlComponent,
        controlComponentDescription,
        controlInputs,
        controlModeDescription,
        controlModeInput,
        dark,
        getWidth,
        handleSystemInputChange,
        motorComponentDescription,
        motorInputs,
        systemLoading,
        systemState
    };
};
