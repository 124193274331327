import React, { useRef } from 'react';
import { TRANSLATIONS } from '../../../constants';
import { ApplicationType, SystemType } from '../../../types';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useCommercialView } from '../../../hooks/application/CommercialView';
import InfoDataGroup from '../InfoDataGroup';
import PriceDataGroup from '../PriceDataGroup';
import Text from '../../common/Text';
import Box from '../../common/Box';
import AdditionalInvestment from './AdditionalInvestment';
import GovernmentFunding from './GovernmentFunding';
import CommercialViewMotorInfo from './ModalInfo/CommercialViewMotorInfo';
import CommercialViewSwitchgearInfo from './ModalInfo/CommercialViewSwitchgearInfo';
import MotorTechnicalDataForm from '../MotorTechnicalDataForm';
import { useTechnicalData } from '../../../hooks';
import CommercialViewConfigurationInfo from './ModalInfo/CommercialViewConfigurationInfo';
import { getMepsWarning } from '../../../services';

interface CommercialViewCardProps {
    systemType: SystemType;
    applicationType: ApplicationType;
}

const CommercialViewCard = ({ systemType, applicationType }: CommercialViewCardProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { dynamicTranslate, translate } = useTranslate();
    const isPumpSystem = applicationType === 'pump';
    const { supplyType } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const {
        commercialData,
        controlComponent,
        controlMode,
        handleControlcomponentCustomerPriceChange,
        handleControlComponentDiscountChange,
        handleControlComponentExpenditureForRepairsChange,
        handleMotorCustomerPriceChange,
        handleMotorDiscountChange,
        handleMotorExpenditureForRepairsChange,
        handleNettoInvestmentChange,
        handleAdditionalInvestmentDataChange,
        handleSystemGovernmentFundingDataChange,
        motor,
        exchangedAdditionalInvestments,
        exchangedMotorListPrice,
        exchangedControlComponentListPrice,
        exchangedMotorCustomerPrice,
        exchangedControlComponentCustomerPrice,
        exchangedTotalInvestment,
        exchangedControlComponentExpenditureForRepairs,
        exchangedMotorExpenditureForRepairs,
        systemState,
        totalInvestment
    } = useCommercialView({ systemType, applicationType });
    const technicalDataFormRef = useRef<HTMLFormElement>(null);
    const { mlfb, callMotorTechnicalData, callControlUnitTechnicalData } = useTechnicalData({ formRef: technicalDataFormRef, applicationType, systemType });
    const hasMepsWarning = getMepsWarning(systemType, useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem));

    return (
        <>
            <Box edgeColor={systemType} dark={dark} style={{ padding: 16 }}>
                <MotorTechnicalDataForm formRef={technicalDataFormRef} mlfb={mlfb} />
                <Text color={systemType} dark={dark} type='subtitle-small'>
                    {systemType == 'reference' ? translate(TRANSLATIONS.generated['System.ReferenceSystem']) : translate(TRANSLATIONS.generated['System.AlternativeSystem'])}
                </Text>
                <InfoDataGroup
                    title={translate(TRANSLATIONS.generated['System.Configuration'])}
                    modalInfoData={<CommercialViewConfigurationInfo applicationType={applicationType} />}
                    application={translate(isPumpSystem ? TRANSLATIONS.generated['System.Pump'] : TRANSLATIONS.generated['System.Fan'])}
                    controlMode={dynamicTranslate(TRANSLATIONS.generated, controlMode.selectedValue.title)}
                    dark={dark}
                    imageId={controlMode.selectedValue.descriptionImageId}
                    lineSupply={supplyType?.name ?? ''}
                />
                <PriceDataGroup
                    customerPrice={exchangedMotorCustomerPrice?.toString() ?? ''}
                    dark={dark}
                    description={systemState?.motorComponent.selectedMotor.marketingName?.marketingName ?? ''}
                    discount={commercialData.motorPrices.discount ?? ''}
                    expenditureForRepairs={exchangedMotorExpenditureForRepairs?.toString() ?? '0'}
                    imageId={motor.selectedValue.imageId}
                    listPrice={exchangedMotorListPrice?.toString()}
                    modalInfoData={<CommercialViewMotorInfo />}
                    warning={hasMepsWarning}
                    onCustomerPriceChange={handleMotorCustomerPriceChange}
                    onDiscountChange={handleMotorDiscountChange}
                    onExpenditureForRepairsChange={handleMotorExpenditureForRepairsChange}
                    title={translate(TRANSLATIONS.generated['System.Motor'])}
                    onTechnicalDataClick={callMotorTechnicalData}
                />
                <PriceDataGroup
                    customerPrice={exchangedControlComponentCustomerPrice?.toString() ?? ''}
                    dark={dark}
                    description={systemState?.controlUnitComponent.selectedControlUnit.marketingName?.marketingName ?? ''}
                    discount={commercialData.controlUnitPrices.discount ?? ''}
                    expenditureForRepairs={exchangedControlComponentExpenditureForRepairs?.toString() ?? '0'}
                    imageId={controlComponent.selectedValue.imageId}
                    listPrice={exchangedControlComponentListPrice?.toString()}
                    modalInfoData={<CommercialViewSwitchgearInfo />}
                    onCustomerPriceChange={handleControlcomponentCustomerPriceChange}
                    onDiscountChange={handleControlComponentDiscountChange}
                    onExpenditureForRepairsChange={handleControlComponentExpenditureForRepairsChange}
                    title={dynamicTranslate(TRANSLATIONS.generated, controlComponent.name)}
                    onTechnicalDataClick={callControlUnitTechnicalData}
                />
            </Box>
            <AdditionalInvestment
                color={systemType}
                dark={dark}
                additionalInvestmentData={commercialData.additionalInvestments}
                exchangedControlComponentCustomerPrice={exchangedControlComponentCustomerPrice?.toString() ?? ''}
                exchangedControlComponentExpenditureForRepairs={exchangedControlComponentExpenditureForRepairs}
                exchangedMotorExpenditureForRepairs={exchangedMotorExpenditureForRepairs}
                exchangedAdditionalInvestmentSum={exchangedAdditionalInvestments ?? 0}
                exchangedMotorCustomerPrice={exchangedMotorCustomerPrice?.toString() ?? ''}
                onAdditionalInvestmentDataChange={handleAdditionalInvestmentDataChange}
            />
            <GovernmentFunding
                color={systemType}
                dark={dark}
                governmentFundingData={commercialData.governmentFunding}
                onGovernmentFundingDataChange={handleSystemGovernmentFundingDataChange}
                onNettoInvestmentChange={handleNettoInvestmentChange}
                totalInvestment={totalInvestment}
                exchangedTotalInvestment={exchangedTotalInvestment ?? 0}
            />
        </>
    );
};

export default CommercialViewCard;
